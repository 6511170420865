import type { ImageLoader, ImageLoaderProps, ImageProps } from 'next/image';

import Image from 'next/image';
import { isValidUrl } from '../../utils/is-valid-url';

// Let's omit props from Image that are handled by the ImageLoader.
// We also omit 'height', as we want to make it mandatory, as Image props allows it to be undefined.
export type ImagePropsOmit = Omit<ImageProps, 'quality' | 'width' | 'src' | 'height'>;

export interface ImageLoaderWrapperProps extends ImagePropsOmit, ImageLoaderProps {
  height: number;
  format?: 'webp' | 'png' | 'jpg';
  grayscale?: boolean;
  rszHeight?: boolean;
}

/**
 * Calculates the proper height of an image with a custom width, preserving the original aspect ratio.
 *
 * @param originalHeight
 * @param originalWidth
 * @param newWidth
 */
function determineNewHeight(originalHeight: number, originalWidth: number, newWidth: number) {
  return Math.ceil((originalHeight / originalWidth) * newWidth);
}

export const ImageLoaderWrapper = (props: ImageLoaderWrapperProps) => {
  const { height, width, grayscale, format, layout, alt, rszHeight = true, ...other } = props;

  const loader: ImageLoader = (loaderProps: ImageLoaderProps) => {
    const { quality: q, width: w, src } = loaderProps;

    // TODO: Check if the provided src is a valid url or not, if yes return it directly.
    const validUrl = isValidUrl(src.replace(/\/+$/, '').replace('/media/', ''));
    // Can find the query properties here;
    // https://gitlab.azerdev.com/huz.byorbit/huz-media/-/blob/master/src/asset/index.js
    // Todo: HUZ2ERA-1106 - Seems our media server won't allow us to resize webp assets by ratio operation.
    // let url = `${src.replace(/\/+$/, '')}/?qty=${q || 75}&fmt=${format || 'webp'}&ar[r]=${width}x${height}&ar[w]=${w}`;
    // let url = `${src.replace(/\/+$/, '')}/?qty=${q || 75}&fmt=${format || 'webp'}&rsz[w]=${w}`;
    let url = `${src.replace(/\/+$/, '')}/?fmt=${format || 'webp'}&rsz[w]=${w}`;
    if (rszHeight) {
      url += `&rsz[h]=${determineNewHeight(height, width, w)}`;
    }
    if (grayscale) url += `&gsc=${grayscale}`;
    // TODO: Remove the conditional logic after updating all the existing sites.
    return validUrl && src.includes('https://media-aws-dev.byorbit.com')
      ? src.replace('/media/', '')
      : validUrl
      ? src.replace('/media/', '').replace(/\/+$/, '')
      : url;
  };

  // Make sure we don't pass width and height when layout is set to 'fill'. Refer to Next/image docs.
  return layout === 'fill' ? (
    <Image {...other} alt={alt} layout={layout} loader={loader} height="100%" className="asd123asd" />
  ) : (
    <Image {...other} alt={alt} width={width} height={height} loader={loader} className="asd123asd" />
  );
};
